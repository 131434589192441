import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Plus, Edit, Book, Trash2, Search } from 'lucide-react';
import ProfileSidebar from './ProfileSidebar';
import Footer from '../landing/Footer';

const MainScreen = ({ decks, onSelectDeck, onCreateDeck, onDeleteDeck, onLogout, user, refreshDecks }) => {
    const [newDeckName, setNewDeckName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const dialogRef = useRef(null);

    useEffect(() => {
        refreshDecks();
    }, [refreshDecks]);

    const handleCreateDeck = () => {
        if (newDeckName.trim()) {
            onCreateDeck(newDeckName);
            setNewDeckName('');
            dialogRef.current.close();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleCreateDeck();
        }
    };

    const filteredDecks = decks.filter(deck => 
        deck.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="min-h-screen flex flex-col bg-snapcards-cream text-black font-segoe">
            <main className="flex-grow w-full">
                <div className="max-w-7xl mx-auto px-4 py-8">
                    <div className="flex flex-wrap items-center justify-between mb-8">
                        <h2 className="text-3xl font-bold text-black mb-4 w-full md:mb-0 md:w-auto">Your Library</h2>
                        <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 w-full md:w-auto">
                            <div className="relative flex-grow md:flex-grow-0 w-full sm:w-auto">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <Input
                                    type="text"
                                    placeholder="Search decks"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 w-full sm:w-64 border-gray-300 focus:border-snapcards-blue focus:ring-snapcards-blue rounded-full"
                                />
                            </div>
                            <Button
                                onClick={() => {
                                    setNewDeckName('');
                                    dialogRef.current.showModal();
                                }}
                                className="bg-snapcards-blue text-white hover:bg-blue-700 rounded-full whitespace-nowrap w-full sm:w-auto"
                            >
                                <Plus className="h-5 w-5 mr-2" /> New Deck
                            </Button>
                        </div>
                    </div>
    
                    <div className="space-y-6">
                        {filteredDecks.map(deck => (
                            <Card key={deck.id} className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200">
                                <CardHeader className="border-b border-gray-200 relative">
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <CardTitle className="text-xl font-bold text-black">{deck.name}</CardTitle>
                                            <CardDescription className="text-gray-600">
                                                {deck.cardCount} cards | Last studied: {deck.lastStudied ? new Date(deck.lastStudied).toLocaleDateString() : 'Never'}
                                            </CardDescription>
                                        </div>
                                        <Button variant="ghost" size="icon" onClick={() => onDeleteDeck(deck.id)} className="absolute top-2 right-2">
                                            <Trash2 className="h-5 w-5 text-red-500 hover:text-red-700" />
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardContent className="flex justify-between items-center p-4">
                                    <div className="flex space-x-4">
                                        <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-semibold">
                                            {deck.newCards} new
                                        </div>
                                        <div className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-semibold">
                                            {deck.cardsDue} due
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <Button onClick={() => onSelectDeck(deck.id, 'edit')} className="bg-snapcards-blue text-white hover:bg-blue-700">
                                            <Edit className="h-4 w-4 mr-2" /> Edit
                                        </Button>
                                        <Button onClick={() => onSelectDeck(deck.id, 'study')} className="bg-snapcards-blue text-white hover:bg-blue-700">
                                            <Book className="h-4 w-4 mr-2" /> Study
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </main>
    
            <dialog ref={dialogRef} className="p-6 rounded-lg shadow-xl w-96">
                <h3 className="text-2xl font-bold mb-4">Create New Deck</h3>
                <Input 
                    type="text" 
                    placeholder="Enter deck name" 
                    value={newDeckName}
                    onChange={(e) => setNewDeckName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="mb-6 w-full text-lg"
                />
                <div className="flex justify-end space-x-3">
                    <Button onClick={() => dialogRef.current.close()} variant="outline" className="px-6 py-2">
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleCreateDeck}
                        className="bg-snapcards-blue text-white hover:bg-blue-700 px-6 py-2"
                    >
                        Create
                    </Button>
                </div>
            </dialog>
    
            <Footer />
        </div>
    );
}

export default MainScreen;