import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Plus, Edit, Book, Trash2, Search } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import Footer from '../landing/Footer';
import DeckSelectionMenu from './DeckSelectionMenu';
import GenerationCard from './GenerationCard';
import * as api from '../../services/api';


const AddTopicDialog = ({ isOpen, onClose, onCreateTopic }) => {
    const [newTopicName, setNewTopicName] = useState('');

    const handleCreateTopic = (e) => {
        e.preventDefault();
        if (newTopicName.trim()) {
            onCreateTopic(newTopicName.trim());
            setNewTopicName('');
            onClose();
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleCreateTopic();
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader className="pb-4">
                    <DialogTitle>Create New Topic</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleCreateTopic} className="space-y-4">
                    <div className="px-1">
                        <Input 
                            type="text" 
                            placeholder="Enter topic name" 
                            value={newTopicName}
                            onChange={(e) => setNewTopicName(e.target.value)}
                            className="w-full"
                        />
                    </div>
                    <DialogFooter>
                        <Button type="button" variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" className="bg-snapcards-blue text-white hover:bg-blue-700">
                            Create
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};


const DeckScreen = ({ deck, onSelectTopic, onCreateTopic, onStudy, onDeckChange }) => {
    const [topics, setTopics] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [deletingTopics, setDeletingTopics] = useState(new Set());
    const [isAddTopicDialogOpen, setIsAddTopicDialogOpen] = useState(false);
    const [allDecks, setAllDecks] = useState([]);

    useEffect(() => {
        if (deck) {
            console.log(`Deck changed. Fetching topics for deck ${deck.id}`);
            fetchTopics();
            fetchAllDecks();
        }
    }, [deck]);

    const fetchTopics = async () => {
        try {
            console.log(`Fetching topics for deck ${deck.id}`);
            const response = await api.getTopics(deck.id);
            console.log(`Topics fetched:`, response.data);
            setTopics(response.data);
        } catch (error) {
            console.error('Error fetching topics:', error);
        }
    };

    const fetchAllDecks = async () => {
        try {
            const response = await api.getDecks();
            console.log('Fetched decks:', response.data);
            setAllDecks(response.data);
        } catch (error) {
            console.error('Error fetching decks:', error);
        }
    };

    const handleCreateTopic = async (newTopicName) => {
        try {
            await onCreateTopic(newTopicName);
            fetchTopics();
        } catch (error) {
            console.error('Error creating topic:', error);
        }
    };

    const handleDeleteTopic = async (topicId) => {
        setTopics(prevTopics => prevTopics.filter(topic => topic.id !== topicId));
        setDeletingTopics(prev => new Set(prev).add(topicId));

        try {
            await api.deleteTopic(topicId);
            setDeletingTopics(prev => {
                const newSet = new Set(prev);
                newSet.delete(topicId);
                return newSet;
            });
        } catch (error) {
            console.error('Error deleting topic:', error);
            fetchTopics();
            setDeletingTopics(prev => {
                const newSet = new Set(prev);
                newSet.delete(topicId);
                return newSet;
            });
        }
    };

    const handleTopicStudy = (deckId, topicId) => {
        onStudy(deckId, topicId);
    };

    const handleDeckSelect = async (deckId) => {
        try {
            const response = await api.getDeck(deckId);
            onDeckChange(response.data);
        } catch (error) {
            console.error('Error fetching selected deck:', error);
        }
    };

    const handleCreateDeck = async (newDeckName) => {
        if (newDeckName.trim()) {
            try {
                const response = await api.createDeck(newDeckName);
                setAllDecks([...allDecks, response.data]);
                onDeckChange(response.data);
            } catch (error) {
                console.error('Error creating new deck:', error);
            }
        }
    };

    const filteredTopics = topics.filter(topic => 
        topic.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !deletingTopics.has(topic.id)
    );

    const handleGenerationComplete = async () => {
        console.log(`Generation complete. Fetching updated topics for deck ${deck.id}`);
        try {
            await fetchTopics();
            console.log('Topics fetched successfully after generation');
        } catch (error) {
            console.error('Error fetching topics after generation:', error);
        }
    };

    return (
        <div className="min-h-screen flex flex-col bg-snapcards-cream text-black font-segoe">
            <main className="flex-grow w-full">
                <div className="max-w-7xl mx-auto px-4 py-8">
                    <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 space-y-4 md:space-y-0 md:space-x-4">
                        <div className="flex items-center space-x-4 flex-shrink-0">
                            <DeckSelectionMenu
                                decks={allDecks}
                                currentDeckId={deck.id}
                                onDeckSelect={handleDeckSelect}
                                onCreateDeck={handleCreateDeck}
                            />
                            <h2 className="text-4xl md:text-5xl font-bold text-black leading-none">{deck.name}</h2>
                            <Button onClick={() => onStudy(deck.id, null)} className="bg-snapcards-blue text-white hover:bg-blue-700">
                                <Book className="h-4 w-4 mr-2" /> Study Deck
                            </Button>
                        </div>
                        <div className="flex items-center space-x-4 w-full">
                            <div className="relative flex-grow">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <Input
                                    type="text"
                                    placeholder="Search topics"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 w-full border-gray-300 focus:border-snapcards-blue focus:ring-snapcards-blue rounded-full"
                                />
                            </div>
                            <Button
                                onClick={() => setIsAddTopicDialogOpen(true)}
                                className="bg-snapcards-blue text-white hover:bg-blue-700 rounded-full whitespace-nowrap flex-shrink-0"
                            >
                                <Plus className="h-5 w-5 mr-2" /> New Topic
                            </Button>
                        </div>
                    </div>

                    <GenerationCard
                        deckId={deck.id}
                        onGenerationComplete={handleGenerationComplete}
                    />

                    <div className="space-y-6">
                        {filteredTopics.map(topic => (
                            <Card key={topic.id} className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200">
                                <CardHeader className="border-b border-gray-200 relative">
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <CardTitle className="text-xl font-bold text-black">{topic.name}</CardTitle>
                                            <CardDescription className="text-gray-600">
                                                {topic.cardCount} cards | Last studied: {topic.lastStudied ? new Date(topic.lastStudied).toLocaleDateString() : 'Never'}
                                            </CardDescription>
                                        </div>
                                        <Button 
                                            variant="ghost" 
                                            size="icon" 
                                            onClick={() => handleDeleteTopic(topic.id)} 
                                            className="absolute top-2 right-2"
                                        >
                                            <Trash2 className="h-5 w-5 text-red-500 hover:text-red-700" />
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardContent className="flex justify-between items-center p-4">
                                    <div className="flex space-x-4">
                                        <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-semibold">
                                            {topic.newCards} new
                                        </div>
                                        <div className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-semibold">
                                            {topic.cardsDue} due
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <Button onClick={() => onSelectTopic(topic.id)} className="bg-snapcards-blue text-white hover:bg-blue-700">
                                            <Edit className="h-4 w-4 mr-2" /> Edit
                                        </Button>
                                        <Button onClick={() => onStudy(deck.id, topic.id)} className="bg-snapcards-blue text-white hover:bg-blue-700">
                                            <Book className="h-4 w-4 mr-2" /> Study Topic
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </main>

            <AddTopicDialog
                isOpen={isAddTopicDialogOpen}
                onClose={() => setIsAddTopicDialogOpen(false)}
                onCreateTopic={handleCreateTopic}
            />

            <Footer />
        </div>
    );
}

export default DeckScreen;
