import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';
import { Plus, ChevronLeft, Edit, Trash2, Search } from 'lucide-react';
import Footer from '../landing/Footer';
import * as api from '../../services/api';

const TopicScreen = ({ topicId, onBack, onUpdateCard, onDeleteCard, onCreateCard }) => {
    const [topic, setTopic] = useState(null);
    const [cards, setCards] = useState([]);
    const [newCardQuestion, setNewCardQuestion] = useState('');
    const [newCardAnswer, setNewCardAnswer] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [editingCard, setEditingCard] = useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [deletingCards, setDeletingCards] = useState(new Set());

    const fetchTopic = useCallback(async () => {
        try {
            const response = await api.getTopic(topicId);
            setTopic(response.data);
            setCards(response.data.cards);
        } catch (error) {
            console.error('Error fetching topic:', error);
        }
    }, [topicId]);

    useEffect(() => {
        fetchTopic();
    }, [fetchTopic]);

    const handleAddCard = async () => {
        if (newCardQuestion.trim() && newCardAnswer.trim()) {
            try {
                const response = await onCreateCard(newCardQuestion, newCardAnswer);
                setCards([...cards, response.data]);
                setNewCardQuestion('');
                setNewCardAnswer('');
            } catch (error) {
                console.error('Error adding card:', error);
            }
        }
    };

    const handleEditCard = async (cardId, updatedCard) => {
        try {
            await onUpdateCard(cardId, updatedCard.question, updatedCard.answer);
            setCards(cards.map(card => card.id === cardId ? {...card, ...updatedCard} : card));
            setEditingCard(null);
            setIsEditDialogOpen(false);
        } catch (error) {
            console.error('Error updating card:', error);
        }
    };

    const handleDeleteCard = async (cardId) => {
        // Optimistically remove the card from the UI
        setCards(prevCards => prevCards.filter(card => card.id !== cardId));
        setDeletingCards(prev => new Set(prev).add(cardId));

        try {
            await onDeleteCard(cardId);
            // If successful, remove the card from the deleting set
            setDeletingCards(prev => {
                const newSet = new Set(prev);
                newSet.delete(cardId);
                return newSet;
            });
        } catch (error) {
            console.error('Error deleting card:', error);
            // If there's an error, revert the optimistic update
            fetchTopic();
            setDeletingCards(prev => {
                const newSet = new Set(prev);
                newSet.delete(cardId);
                return newSet;
            });
        }
    };

    const filteredCards = cards.filter(card => 
        (card.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        card.answer.toLowerCase().includes(searchTerm.toLowerCase())) &&
        !deletingCards.has(card.id)
    );

    if (!topic) {
        return <div>Loading...</div>;
    }

    return (
        <div className="min-h-screen flex flex-col bg-snapcards-cream text-black font-segoe">
        <main className="flex-grow w-full">
            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 space-y-4 md:space-y-0 md:space-x-4">
                    <h2 className="text-3xl font-bold text-black flex-shrink-0">{topic.name}</h2>
                    <div className="flex items-center space-x-4 w-full md:flex-1">
                        <div className="relative flex-grow w-full">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            <Input
                                type="text"
                                placeholder="Search cards"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10 pr-4 py-2 w-full border-gray-300 focus:border-snapcards-blue focus:ring-snapcards-blue rounded-full"
                            />
                        </div>
                        <Button
                            onClick={() => document.getElementById('newCardDialog').showModal()}
                            className="bg-snapcards-blue text-white hover:bg-blue-700 rounded-full whitespace-nowrap flex-shrink-0"
                        >
                            <Plus className="h-5 w-5 mr-2" /> Add Card
                        </Button>
                    </div>
                </div>

                    <div className="space-y-4">
                        {filteredCards.map((card) => (
                            <Card 
                                key={card.id}
                                className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200"
                            >
                                <CardContent className="p-4 flex justify-between items-start">
                                    <div>
                                        <CardTitle className="text-lg font-semibold mb-2">{card.question}</CardTitle>
                                        <CardDescription>{card.answer}</CardDescription>
                                    </div>
                                    <div className="flex space-x-2">
                                        <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
                                            <DialogTrigger asChild>
                                                <Button variant="outline" size="icon" onClick={() => {
                                                    setEditingCard(card);
                                                    setIsEditDialogOpen(true);
                                                }}>
                                                    <Edit className="h-4 w-4" />
                                                </Button>
                                            </DialogTrigger>
                                            <DialogContent className="bg-white p-4 rounded-lg">
                                                <DialogHeader>
                                                    <DialogTitle className="text-xl font-bold mb-4">Edit Card</DialogTitle>
                                                </DialogHeader>
                                                {editingCard && (
                                                    <form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        handleEditCard(editingCard.id, {
                                                            question: e.target.question.value,
                                                            answer: e.target.answer.value
                                                        });
                                                    }}>
                                                        <Input
                                                            name="question"
                                                            defaultValue={editingCard.question}
                                                            placeholder="Question"
                                                            className="mb-2 w-full"
                                                        />
                                                        <Textarea
                                                            name="answer"
                                                            defaultValue={editingCard.answer}
                                                            placeholder="Answer"
                                                            className="mb-4 w-full"
                                                        />
                                                        <div className="flex justify-end space-x-2">
                                                            <Button type="button" variant="outline" onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
                                                            <Button type="submit" className="bg-snapcards-blue text-white hover:bg-blue-700">Save Changes</Button>
                                                        </div>
                                                    </form>
                                                )}
                                            </DialogContent>
                                        </Dialog>
                                        <Button 
                                            variant="outline" 
                                            size="icon" 
                                            onClick={() => handleDeleteCard(card.id)}
                                        >
                                            <Trash2 className="h-4 w-4 text-red-500 hover:text-red-700" />
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>

                    <Button onClick={onBack} className="mt-8 bg-snapcards-blue text-white hover:bg-blue-700">
                        <ChevronLeft className="h-4 w-4 mr-2" /> Back to Deck
                    </Button>
                </div>
            </main>

            <Footer />

            <dialog id="newCardDialog" className="p-6 rounded-lg shadow-xl bg-white w-full max-w-4xl">
                <div className="flex justify-between items-center mb-6">
                    <h3 className="text-2xl font-bold">Add New Card</h3>
                    <Button onClick={() => document.getElementById('newCardDialog').close()} variant="outline" size="icon">
                        X
                    </Button>
                </div>
                <div className="space-y-4">
                    <div>
                        <label htmlFor="question" className="block text-sm font-medium text-gray-700 mb-1">Question</label>
                        <Input 
                            id="question"
                            type="text" 
                            placeholder="Enter question" 
                            value={newCardQuestion}
                            onChange={(e) => setNewCardQuestion(e.target.value)}
                            className="w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="answer" className="block text-sm font-medium text-gray-700 mb-1">Answer</label>
                        <Textarea 
                            id="answer"
                            placeholder="Enter answer" 
                            value={newCardAnswer}
                            onChange={(e) => setNewCardAnswer(e.target.value)}
                            className="w-full h-32"
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-2 mt-6">
                    <Button onClick={() => document.getElementById('newCardDialog').close()} variant="outline">
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => {
                            handleAddCard();
                            document.getElementById('newCardDialog').close();
                        }} 
                        className="bg-snapcards-blue text-white hover:bg-blue-700"
                    >
                        Add Card
                    </Button>
                </div>
            </dialog>
        </div>
    );
};

export default TopicScreen;