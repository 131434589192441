import axios from 'axios';

console.log(process.env.REACT_APP_API_URL)
const API_URL = process.env.REACT_APP_API_URL || 'https://api.snapcards.online';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor for adding the auth token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return Promise.reject(error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    return Promise.reject({ msg: "No response received from the server. Please try again later." });
  } else {
    // Something happened in setting up the request that triggered an Error
    return Promise.reject({ msg: error.message || "An unexpected error occurred. Please try again." });
  }
});

// Auth endpoints
export const login = (username, password) => api.post('/login', { username, password });
export const register = (username, email, password) => api.post('/register', { username, email, password });
export const getUserData = () => api.get('/user');
export const checkUsername = (username) => api.post('/check_username', { username });
export const checkEmail = (email) => api.post('/check_email', { email });
export const resetPasswordRequest = (email) => api.post('/reset_password_request', { email });
export const resetPassword = (token, password) => api.post(`/reset_password/${token}`, { password });
export const deleteAccount = () => api.delete('/delete_account');
export const resendVerificationEmail = (user_identifier) => api.post('/resend_verification_email', { user_identifier });
export const googleLogin = () => {
  window.location.href = `${API_URL}/login/google`;
};
export const handleOAuthCallback = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return getUserData()
      .then(response => {
        console.log('User data fetched successfully after OAuth');
        return token;
      })
      .catch(error => {
        console.error('Failed to fetch user data after OAuth:', error);
        localStorage.removeItem('token');
        delete api.defaults.headers.common['Authorization'];
        return Promise.reject('Failed to validate token');
      });
  }
  return Promise.reject('No token received');
};
export const verifyEmail = (token) => api.get(`/verify_email/${token}`).then(response => {
  if (response.data && response.data.access_token) {
    localStorage.setItem('token', response.data.access_token);
  }
  return response;
}).catch(error => {
  console.error('Email verification error:', error);
  throw error;
});

// Deck endpoints
export const getDecks = () => api.get('/decks');
export const createDeck = (name) => api.post('/decks', { name });
export const updateDeck = (id, name) => api.put(`/decks/${id}`, { name });
export const deleteDeck = (id) => api.delete(`/decks/${id}`);

// Card endpoints
export const getCards = (deckId) => api.get(`/decks/${deckId}/cards`);
export const createCard = (deckId, question, answer) => api.post(`/decks/${deckId}/cards`, { question, answer });
export const updateCard = (cardId, question, answer) => api.put(`/cards/${cardId}`, { question, answer });
export const deleteCard = (cardId) => api.delete(`/cards/${cardId}`);
export const getRandomCards = (deckId) => api.get(`/decks/${deckId}/random`);

// AI endpoints
export const generateCards = (deckId, formData) => {
  console.log('generateCards called with deckId:', deckId);
  return api.post(`/decks/${deckId}/generate_cards`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).catch(error => {
    if (error.response) {
      console.error("Data:", error.response.data);
      console.error("Status:", error.response.status);
      console.error("Headers:", error.response.headers);
    } else if (error.request) {
      console.error("Request:", error.request);
    } else {
      console.error('Error:', error.message);
    }
    throw error;
  });
};

// Study endpoints
export const getCardsToStudy = (deckId) => api.get(`/decks/${deckId}/study`);
export const reviewCard = (cardId, difficulty) => api.post(`/cards/${cardId}/review`, { difficulty });
export const resetCardReview = (cardId) => api.post(`/cards/${cardId}/reset`);

export default api;