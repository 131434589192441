import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { Button } from '../../components/ui/button';

const Header = ({ title, logo, navigation }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-snapcards-cream sticky top-0 z-10 border-b border-black">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center">
            <img src="/icon.png" alt={logo.alt} className="h-8 w-8 mr-2" />
            <h1 className="text-2xl font-bold text-black font-segoe">{title}</h1>
          </Link>
          <button
            className="md:hidden p-2 hover:bg-gray-100 transition-colors duration-200"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          <nav className="hidden md:flex md:items-center md:space-x-6">
            {navigation.map((item, index) => (
              item.label === "Login" ? (
                <Button
                  key={index}
                  asChild
                  className="bg-snapcards-blue text-white hover:bg-blue-700"
                >
                  <Link to={item.path}>{item.label}</Link>
                </Button>
              ) : (
                <Link
                  key={index}
                  to={item.path}
                  className="text-black hover:text-snapcards-blue"
                >
                  {item.label}
                </Link>
              )
            ))}
          </nav>
        </div>
      </div>
      {isMenuOpen && (
        <nav className="md:hidden bg-snapcards-cream border-t border-gray-200">
          {navigation.map((item, index) => (
            <React.Fragment key={index}>
              {item.label === "Login" ? (
                <Button
                  asChild
                  className="w-full bg-snapcards-blue text-white hover:bg-blue-700 rounded-none"
                >
                  <Link
                    to={item.path}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.label}
                  </Link>
                </Button>
              ) : (
                <Link
                  to={item.path}
                  className="block py-3 px-4 text-black hover:text-snapcards-blue"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.label}
                </Link>
              )}
              {index < navigation.length - 1 && (
                <div className="border-b border-gray-200"></div>
              )}
            </React.Fragment>
          ))}
        </nav>
      )}
    </header>
  );
};

export default Header;