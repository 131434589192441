import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../ui/card';
import { Button } from '../ui/button';
import { ChevronLeft, RotateCcw, Book, Check, X } from 'lucide-react';
import ProfileSidebar from './ProfileSidebar';
import Footer from '../landing/Footer';
import * as api from '../../services/api';

const DeckStudyScreen = ({ deck, topic, onBack, onStudyComplete }) => {
    const [cardsToStudy, setCardsToStudy] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);
    const [studyHistory, setStudyHistory] = useState([]);
    const [statistics, setStatistics] = useState({ correct: 0, total: 0 });
    const [isStudyComplete, setIsStudyComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isFreeReview, setIsFreeReview] = useState(false);
    const [freeReviewCount, setFreeReviewCount] = useState(0);

    const answerQueue = useRef([]);
    const processingQueue = useRef(false);
    const lastConfirmedIndex = useRef(-1);

    const fetchCardsToStudy = useCallback(async () => {
    console.log('fetchCardsToStudy called with deck:', deck, 'and topic:', topic);
    console.log('Current isFreeReview state:', isFreeReview);

    if (!deck && !topic) {
        console.error('Both deck and topic are undefined in fetchCardsToStudy');
        return;
    }

    setIsLoading(true);

    try {
        let response;
        if (!topic) {
            console.log('Topic is null. Fetching cards to study from deck ', deck.id);
            if (isFreeReview) {
                console.log('Calling getRandomCards for deck ', deck.id);
                response = await api.getRandomCards(deck.id, 'deck');
            } else {
                console.log('Calling getCardsToStudy for deck ', deck.id);
                response = await api.getCardsToStudy(deck.id, 'deck');
            }
        } else {
            console.log('Topic is not null. Fetching cards to study from topic ', topic.id);
            if (isFreeReview) {
                console.log('Calling getRandomCards for topic ', topic.id);
                response = await api.getRandomCards(topic.id, 'topic');
            } else {
                console.log('Calling getCardsToStudy for topic ', topic.id);
                response = await api.getCardsToStudy(topic.id, 'topic')
            }
        }
        console.log(`response received: ${response.data}`)
        setCardsToStudy(response.data);
        setCurrentCardIndex(0);
        setShowAnswer(false);
        setStudyHistory([]);
        setStatistics({ correct: 0, total: 0 });
        setIsStudyComplete(response.data.length === 0);
    } catch (error) {
        console.error(`Error in fetchCardsToStudy:`, error);
        if (error.response) {
            console.error('Error response:', error.response);
        }
    } finally {
        setIsLoading(false);
    }
    }, [deck, topic, isFreeReview]);
    
    const processAnswerQueue = useCallback(async () => {
        if (processingQueue.current) return;
        processingQueue.current = true;

        while (answerQueue.current.length > 0) {
            const { cardId, difficulty } = answerQueue.current[0];
            try {
                await api.reviewCard(cardId, difficulty);
                answerQueue.current.shift();
                lastConfirmedIndex.current++;
            } catch (error) {
                console.error('Error processing answer:', error);
                break;
            }
        }

        processingQueue.current = false;
    }, []);

    const startFreeReview = () => {
        setIsFreeReview(true);
        setFreeReviewCount(prev => prev + 1);
        fetchCardsToStudy();
    };

    useEffect(() => {
        fetchCardsToStudy();
    }, [fetchCardsToStudy]);

    useEffect(() => {
        const interval = setInterval(processAnswerQueue, 1000);
        return () => clearInterval(interval);
    }, [processAnswerQueue]);

    const currentCard = cardsToStudy[currentCardIndex];

    const toggleAnswer = () => {
        setShowAnswer(true);
    };

    const handleRecordAnswer = async (difficulty) => {
        if (!currentCard) return;

        // Optimistic update
        setStudyHistory(prev => [...prev, { card: currentCard, difficulty, date: new Date() }]);
        setStatistics(prev => {
            const newTotal = prev.total + 1;
            const newCorrect = difficulty === 'EASY' ? prev.correct + 1 : prev.correct;
            return { correct: newCorrect, total: newTotal };
        });

        // Queue the answer
        answerQueue.current.push({ cardId: currentCard.id, difficulty });

        // Move to next card or complete study
        if (currentCardIndex < cardsToStudy.length - 1) {
            setCurrentCardIndex(prev => prev + 1);
            setShowAnswer(false);
        } else {
            setIsStudyComplete(true);
            // Remove the immediate call to onStudyComplete
        }

        // Trigger queue processing
        processAnswerQueue();
    };

    const handleCompleteStudy = (deck) => {
        console.log('Calling onStudyComplete with deck ', deck )
        onStudyComplete(deck.id);
        onBack(deck.id);
    };

    const undoLastAnswer = async () => {
        if (studyHistory.length > 0 && currentCardIndex > 0) {
            const lastEntry = studyHistory[studyHistory.length - 1];
            setStudyHistory(prev => prev.slice(0, -1));
            setStatistics(prev => ({
                ...prev,
                total: prev.total - 1,
                correct: lastEntry.difficulty === 'EASY' ? prev.correct - 1 : prev.correct
            }));
            
            setCurrentCardIndex(prev => prev - 1);
            setShowAnswer(false);

            // Remove the last entry from the queue if it hasn't been processed yet
            if (answerQueue.current.length > 0 && answerQueue.current[answerQueue.current.length - 1].cardId === lastEntry.card.id) {
                answerQueue.current.pop();
            } else {
                // If it's already been processed, we need to make an API call to reset it
                try {
                    await api.resetCardReview(lastEntry.card.id);
                } catch (error) {
                    console.error('Error undoing last answer:', error);
                }
            }
        }
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <Card className="w-full max-w-2xl bg-white shadow-md border border-gray-200">
                    <CardContent className="flex justify-center items-center h-64">
                        <p>Loading...</p>
                    </CardContent>
                </Card>
            );
        }

        if (isStudyComplete || cardsToStudy.length === 0) {
            return (
                <Card className="w-full max-w-2xl bg-white shadow-md border border-gray-200">
                    <CardHeader>
                        <CardTitle className="text-2xl font-bold">
                            {isStudyComplete ? "Study session complete!" : "No cards to study"}
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="mb-4">
                            {isStudyComplete 
                                ? `You've completed your ${isFreeReview ? 'free review' : 'regular study'} session.` 
                                : "There are no cards due for study at this time."}
                        </p>
                        {isStudyComplete && !isFreeReview && (
                            <p className="mb-4">Accuracy: {statistics.total > 0 ? ((statistics.correct / statistics.total) * 100).toFixed(2) : 0}%</p>
                        )}
                        <div className="flex space-x-4">
                            <Button 
                                onClick={startFreeReview} 
                                className="bg-snapcards-blue text-white hover:bg-blue-700"
                            >
                                {freeReviewCount > 0 ? "Continue Free Review" : "Start Free Review"}
                            </Button>
                            <Button 
                                onClick={() => handleCompleteStudy(deck)}
                                className="bg-gray-500 text-white hover:bg-gray-700"
                            >
                                Back to Main
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            );
        }

        return (
            <>
                <Card className="w-full max-w-2xl bg-white shadow-md border border-gray-200 mb-4">
                    <CardHeader>
                        <CardTitle className="text-2xl font-bold">Study: {topic ? topic.name : deck.name}</CardTitle>
                        <CardDescription>
                            Progress: {currentCardIndex + 1} / {cardsToStudy.length} | 
                            Accuracy: {statistics.total > 0 ? ((statistics.correct / statistics.total) * 100).toFixed(2) : 0}%
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="h-64 flex flex-col justify-between">
                        <div className="flex-grow flex items-center justify-center p-6">
                            <p className="text-xl text-center">
                                {currentCard && (showAnswer ? currentCard.answer : currentCard.question)}
                            </p>
                        </div>
                        <div className="flex justify-center p-4 bg-gray-50 rounded-b-lg">
                            {!showAnswer ? (
                                <Button onClick={toggleAnswer} className="bg-snapcards-blue text-white hover:bg-blue-700">
                                    Show Answer
                                </Button>
                            ) : (
                                <div className="flex justify-center space-x-4">
                                    <Button onClick={() => handleRecordAnswer('HARD')} variant="outline" className="bg-red-100 hover:bg-red-200">
                                        <X className="h-4 w-4 mr-2 text-red-500" /> Hard
                                    </Button>
                                    <Button onClick={() => handleRecordAnswer('MEDIUM')} variant="outline" className="bg-yellow-100 hover:bg-yellow-200">
                                        <Book className="h-4 w-4 mr-2 text-yellow-500" /> Medium
                                    </Button>
                                    <Button onClick={() => handleRecordAnswer('EASY')} variant="outline" className="bg-green-100 hover:bg-green-200">
                                        <Check className="h-4 w-4 mr-2 text-green-500" /> Easy
                                    </Button>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>

                <Card className="w-full max-w-2xl bg-white shadow-md border border-gray-200 mb-4">
                    <CardContent className="p-4">
                        <div className="flex justify-center space-x-4">
                            <Button onClick={undoLastAnswer} disabled={studyHistory.length === 0} className="bg-gray-200 text-gray-800 hover:bg-gray-300">
                                <RotateCcw className="h-4 w-4 mr-2" /> Undo Last Answer
                            </Button>
                            <Button onClick={() => handleCompleteStudy(deck)} className="bg-snapcards-blue text-white hover:bg-blue-700">
                                <ChevronLeft className="h-4 w-4 mr-2" /> Back to Main
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </>
        );
    };

    return (
        <div className="min-h-screen bg-snapcards-cream text-black font-segoe">
            <main className="max-w-7xl mx-auto px-4 py-8">
                <div className="flex flex-col items-center justify-center">
                    {renderContent()}
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default DeckStudyScreen;