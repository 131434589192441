import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { LogIn, Loader2 } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/alert';
import { googleLogin, resendVerificationEmail, resetPasswordRequest, login, getUserData } from '../../services/api';

const LoginScreen = ({ onLogin, onRegisterClick }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userIdentifier, setUserIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [showResendLink, setShowResendLink] = useState(false);
    const [showResetPasswordLink, setShowResetPasswordLink] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log('Running useEffect for OAuth callback handling');
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const error = params.get('error');

        if (token) {
            console.log('OAuth token received, processing...');
            localStorage.setItem('token', token);
            getUserData()
                .then((response) => {
                    console.log('User data fetched successfully');
                    onLogin(response.data);
                })
                .catch((error) => {
                    console.error('Failed to get user data:', error);
                    setFeedbackMessage("Failed to process login. Please try again.");
                    setFeedbackType('error');
                });
        } else if (error) {
            console.error('OAuth error:', error);
            setFeedbackMessage(decodeURIComponent(error));
            setFeedbackType('error');
        }
    }, [location, navigate, onLogin]);

    const clearFeedback = () => {
        setFeedbackMessage('');
        setFeedbackType('');
        setShowResendLink(false);
        setShowResetPasswordLink(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        clearFeedback();
        setIsLoading(true);
        try {
            const response = await login(userIdentifier, password);
            localStorage.setItem('token', response.data.access_token);
            onLogin(response.data.user);
            navigate('/dashboard', { replace: true });
        } catch (error) {
            console.error('Login error:', error);
            if (error.msg === "Please verify your email address before logging in") {
                setFeedbackMessage("Please verify your email address before logging in. Can't find it?");
                setShowResendLink(true);
            } else if (error.msg === "Invalid password.") {
                setFeedbackMessage("Invalid password. Forgot your password?");
                setShowResetPasswordLink(true);
            } else {
                setFeedbackMessage(error.msg || "An unexpected error occurred");
            }
            setFeedbackType('error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendVerificationEmail = async (e) => {
        e.preventDefault();
        try {
            await resendVerificationEmail(userIdentifier);
            setFeedbackMessage("Verification email resent. Please check your inbox.");
            setFeedbackType('success');
            setShowResendLink(false);
        } catch (error) {
            console.error('Resend verification email error:', error);
            setFeedbackMessage("Failed to resend verification email. Please try again.");
            setFeedbackType('error');
        }
    };

    const handleResetPasswordRequest = async (e) => {
        e.preventDefault();
        try {
            await resetPasswordRequest(userIdentifier);
            setFeedbackMessage("Password reset email sent. Please check your inbox.");
            setFeedbackType('success');
            setShowResetPasswordLink(false);
        } catch (error) {
            console.error('Reset password request error:', error);
            setFeedbackMessage("Failed to request password reset. Please try again.");
            setFeedbackType('error');
        }
    };

    const handleGoogleLogin = () => {
        googleLogin();
    };

    return (
        <div className="min-h-screen bg-snapcards-cream text-black font-segoe flex items-center justify-center">
            <Card className="w-full max-w-md bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200">
                <CardHeader>
                    <CardTitle className="text-3xl font-bold text-center text-snapcards-blue">Log In</CardTitle>
                </CardHeader>
                <CardContent>
                    {feedbackMessage && (
                        <Alert variant={feedbackType === 'error' ? 'destructive' : 'default'} className="mb-6">
                            <AlertDescription>
                                {feedbackMessage}
                                {showResendLink && (
                                    <>
                                        {' '}
                                        <a
                                            href="#"
                                            onClick={handleResendVerificationEmail}
                                            className="text-blue-500 hover:text-blue-700 underline"
                                        >
                                            Resend verification email
                                        </a>
                                    </>
                                )}
                                {showResetPasswordLink && (
                                    <>
                                        {' '}
                                        <a
                                            href="#"
                                            onClick={handleResetPasswordRequest}
                                            className="text-blue-500 hover:text-blue-700 underline"
                                        >
                                            Reset password
                                        </a>
                                    </>
                                )}
                            </AlertDescription>
                        </Alert>
                    )}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <Input
                            type="text"
                            placeholder="Username or Email"
                            value={userIdentifier}
                            onChange={(e) => {
                                setUserIdentifier(e.target.value);
                                clearFeedback();
                            }}
                            required
                            className="border-gray-300 focus:border-snapcards-blue focus:ring-snapcards-blue"
                        />
                        <Input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                clearFeedback();
                            }}
                            required
                            className="border-gray-300 focus:border-snapcards-blue focus:ring-snapcards-blue"
                        />
                        <Button 
                            type="submit" 
                            className="w-full bg-snapcards-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Logging in...
                                </>
                            ) : (
                                <>
                                    <LogIn className="h-5 w-5 mr-2" /> 
                                    Login
                                </>
                            )}
                        </Button>
                    </form>
                    <div className="mt-8 relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">Or continue with</span>
                        </div>
                    </div>
                    <div className="mt-6">
                        <Button
                            onClick={handleGoogleLogin}
                            className="w-full bg-white hover:bg-gray-50 text-gray-900 border border-gray-300 font-bold py-2 px-4 rounded flex items-center justify-center space-x-2 transition-colors duration-300"
                        >
                            <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                                <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                                <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                                <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                            </svg>
                            <span>Sign in with Google</span>
                        </Button>
                    </div>
                    <p className="text-center mt-8 text-sm text-gray-600">
                        Don't have an account?{' '}
                        <Button variant="link" onClick={onRegisterClick} className="p-0 text-snapcards-blue hover:text-blue-700 font-bold">
                            Register
                        </Button>
                    </p>
                </CardContent>
            </Card>
        </div>
    );
};

export default LoginScreen;