import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import MainScreen from './MainScreen';
import DeckEditScreen from './DeckEditScreen';
import DeckStudyScreen from './DeckStudyScreen';
import LoginScreen from './LoginScreen';
import RegisterScreen from './RegisterScreen';
import RegistrationConfirmedScreen from './RegistrationConfirmedScreen';
import EmailVerificationScreen from './EmailVerificationScreen';
import Header from './Header';
import { Button } from '../ui/button';
import { Edit, Book } from 'lucide-react';
import * as api from '../../services/api';

const Snapcards = () => {
    const [decks, setDecks] = useState([]);
    const [currentDeck, setCurrentDeck] = useState(null);
    const [currentScreen, setCurrentScreen] = useState('login');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [registeredEmail, setRegisteredEmail] = useState('');
    const [isFreeReview, setIsFreeReview] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const userData = await api.getUserData();
                    setUser(userData.data);
                    setIsAuthenticated(true);
                    setCurrentScreen('main');
                    fetchDecks();
                } catch (error) {
                    console.error('Error verifying token:', error);
                    localStorage.removeItem('token');
                }
            }
            setIsLoading(false);
        };
        checkAuth();
    }, []);

    const fetchDecks = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const response = await api.getDecks();
                setDecks(response.data);
            } catch (error) {
                console.error('Error fetching decks:', error);
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchDecks();
        }
    }, [isAuthenticated, fetchDecks]);

    const handleLogin = async (username, password) => {
        try {
            const response = await api.login(username, password);
            localStorage.setItem('token', response.data.access_token);
            const userData = await api.getUserData();
            setUser(userData.data);
            setIsAuthenticated(true);
            setCurrentScreen('main');
            fetchDecks();
        } catch (error) {
            console.error('Login error:', error);
            throw error.msg || 'An unexpected error occurred';
        }
    };

    const handleRegister = async (username, email, password) => {
        try {
            await api.register(username, email, password);
            setRegisteredEmail(email);
            setCurrentScreen('registrationConfirmed');
        } catch (error) {
            console.error('Registration error:', error);
            throw error.msg || 'An unexpected error occurred';
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setCurrentScreen('login');
        setDecks([]);
        setCurrentDeck(null);
        setUser(null);
        navigate('/');
    };

    const addDeck = async (name) => {
        try {
            const response = await api.createDeck(name);
            setDecks([...decks, response.data]);
        } catch (error) {
            console.error('Error creating deck:', error);
        }
    };

    const deleteDeck = async (id) => {
        try {
            await api.deleteDeck(id);
            setDecks(decks.filter(deck => deck.id !== id));
        } catch (error) {
            console.error('Error deleting deck:', error);
        }
    };

    const selectDeck = (deckId, screen, freeReview = false) => {
        const selectedDeck = decks.find(deck => deck.id === deckId);
        setCurrentDeck(selectedDeck);
        setCurrentScreen(screen);
        setIsFreeReview(freeReview);
    };

    const handleStudyComplete = () => {
        fetchDecks();
        setIsFreeReview(false);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated || !user) {
        switch (currentScreen) {
            case 'login':
                return <LoginScreen onLogin={handleLogin} onRegisterClick={() => setCurrentScreen('register')} />;
            case 'register':
                return <RegisterScreen onRegister={handleRegister} onLoginClick={() => setCurrentScreen('login')} />;
            case 'registrationConfirmed':
                return <RegistrationConfirmedScreen onLoginClick={() => setCurrentScreen('login')} email={registeredEmail} />;
            case 'emailVerification':
                return <EmailVerificationScreen />;
            default:
                return <LoginScreen onLogin={handleLogin} onRegisterClick={() => setCurrentScreen('register')} />;
        }
    }

    return (
        <div>
            <Header 
                user={user} 
                onLogout={handleLogout} 
                setCurrentScreen={setCurrentScreen} 
            />
            {currentScreen === 'main' && (
                <MainScreen 
                    decks={decks} 
                    onSelectDeck={selectDeck} 
                    onCreateDeck={addDeck} 
                    onDeleteDeck={deleteDeck}
                    onLogout={handleLogout}
                    user={user}
                    refreshDecks={fetchDecks}
                />
            )}
            {currentScreen === 'edit' && currentDeck && (
                <DeckEditScreen 
                    deck={currentDeck} 
                    onBack={() => setCurrentScreen('main')} 
                    user={user}
                    onLogout={handleLogout}
                />
            )}
            {currentScreen === 'study' && currentDeck && (
                <DeckStudyScreen 
                    deck={currentDeck} 
                    onBack={() => {
                        setCurrentScreen('main');
                        fetchDecks();
                    }}
                    user={user}
                    onLogout={handleLogout}
                    onStudyComplete={handleStudyComplete}
                    isFreeReview={isFreeReview}
                    startFreeReview={() => selectDeck(currentDeck.id, 'study', true)}
                />
            )}
            {currentDeck && currentScreen !== 'main' && (
                <div className="fixed bottom-4 right-4 flex space-x-2">
                    <Button 
                        onClick={() => setCurrentScreen('edit')} 
                        variant="outline"
                        className="bg-gray-800 text-white hover:bg-gray-700"
                    >
                        <Edit className="h-4 w-4 mr-2" /> Edit
                    </Button>
                    <Button 
                        onClick={() => setCurrentScreen('study')} 
                        variant="outline"
                        className="bg-gray-800 text-white hover:bg-gray-700"
                    >
                        <Book className="h-4 w-4 mr-2" /> Study
                    </Button>
                </div>
            )}
            <Button onClick={() => navigate('/')}>Back to Landing Page</Button>
        </div>
    );
};


export default Snapcards;