import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';
import { Plus, ChevronLeft, Edit, Trash2, Search, Upload, Loader, X } from 'lucide-react';
import ProfileSidebar from './ProfileSidebar';
import Footer from '../landing/Footer';
import * as api from '../../services/api';

const DeckEditScreen = ({ deck, onBack, user, onLogout }) => {
    const [cards, setCards] = useState([]);
    const [newCardQuestion, setNewCardQuestion] = useState('');
    const [newCardAnswer, setNewCardAnswer] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [editingCard, setEditingCard] = useState(null);
    const [file, setFile] = useState(null);
    const [instructions, setInstructions] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);

    const fetchCards = useCallback(async () => {
        try {
            const response = await api.getCards(deck.id);
            setCards(response.data);
        } catch (error) {
            console.error('Error fetching cards:', error);
        }
    }, [deck.id]);

    useEffect(() => {
        fetchCards();
    }, [fetchCards]);

    const handleAddCard = async () => {
        if (newCardQuestion.trim() && newCardAnswer.trim()) {
            try {
                const response = await api.createCard(deck.id, newCardQuestion, newCardAnswer);
                setCards([...cards, response.data]);
                setNewCardQuestion('');
                setNewCardAnswer('');
            } catch (error) {
                console.error('Error adding card:', error);
            }
        }
    };

    const handleEditCard = async (cardId, updatedCard) => {
        if (!cardId) {
            console.error('Invalid card ID');
            return;
        }
        try {
            await api.updateCard(cardId, updatedCard.question, updatedCard.answer);
            setCards(cards.map(card => card.id === cardId ? {...card, ...updatedCard} : card));
            setEditingCard(null);
        } catch (error) {
            console.error('Error updating card:', error);
        }
    };

    const handleDeleteCard = async (cardId) => {
        if (!cardId) {
            console.error('Attempted to delete a card with an invalid ID');
            return;
        }
        try {
            await api.deleteCard(cardId);
            setCards(cards.filter(card => card.id !== cardId));
        } catch (error) {
            console.error('Error deleting card:', error);
        }
    };

    const acceptedFileTypes = [
        '.txt', '.md', '.pdf', '.docx', '.html', '.htm', '.xlsx', 
        '.csv', '.rtf', '.pptx', '.odt', '.json', '.epub', '.xml', '.enex'
    ];

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileExtension = '.' + selectedFile.name.split('.').pop().toLowerCase();
            if (acceptedFileTypes.includes(fileExtension)) {
                setFile(selectedFile);
            } else {
                alert('Unsupported file type. Please select a file with one of the following extensions: ' + acceptedFileTypes.join(', '));
                event.target.value = null; // Reset the file input
            }
        }
    };

    const handleGenerateCards = async () => {
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }

        setIsGenerating(true);

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('instructions', instructions);
            formData.append('deckId', deck.id);

            const response = await api.generateCards(deck.id, formData);
            const newCards = response.data;

            setCards([...cards, ...newCards]);
            setFile(null);
            setInstructions('');
        } catch (error) {
            console.error('Error generating cards:', error);
            alert('An error occurred while generating cards. Please try again.');
        } finally {
            setIsGenerating(false);
        }
    };

    const filteredCards = cards.filter(card => 
        card.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        card.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="min-h-screen flex flex-col bg-snapcards-cream text-black font-segoe">
            <main className="flex-grow w-full">
                <div className="max-w-7xl mx-auto px-4 py-8">
                    <div className="flex flex-wrap items-center justify-between mb-8">
                        <h2 className="text-3xl font-bold text-black mb-4 w-full md:mb-0 md:w-auto">Edit Deck: {deck.name}</h2>
                        <div className="flex items-center space-x-4 w-full md:w-auto">
                            <div className="relative flex-grow md:flex-grow-0">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <Input
                                    type="text"
                                    placeholder="Search cards"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 w-full md:w-64 border-gray-300 focus:border-snapcards-blue focus:ring-snapcards-blue rounded-full"
                                />
                            </div>
                            <Button
                                onClick={() => document.getElementById('newCardDialog').showModal()}
                                className="bg-snapcards-blue text-white hover:bg-blue-700 rounded-full whitespace-nowrap"
                            >
                                <Plus className="h-5 w-5 mr-2" /> Add Card
                            </Button>
                        </div>
                    </div>
    
                    <Card className="mb-8 bg-white shadow-md border border-gray-200">
                        <CardHeader>
                            <CardTitle className="text-xl font-bold">Generate Cards</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flex items-center space-x-4">
                                <Input 
                                    type="file" 
                                    onChange={handleFileChange} 
                                    accept={acceptedFileTypes.join(',')}
                                    className="flex-grow"
                                />
                                <Textarea 
                                    placeholder="Special instructions for card generation..." 
                                    value={instructions}
                                    onChange={(e) => setInstructions(e.target.value)}
                                    className="flex-grow"
                                />
                                <Button 
                                    onClick={handleGenerateCards} 
                                    disabled={isGenerating || !file} 
                                    className="bg-snapcards-blue text-white hover:bg-blue-700"
                                >
                                    {isGenerating ? (
                                        <>
                                            <Loader className="h-4 w-4 mr-2 animate-spin" />
                                            Generating...
                                        </>
                                    ) : (
                                        <>
                                            <Upload className="h-4 w-4 mr-2" />
                                            Generate
                                        </>
                                    )}
                                </Button>
                            </div>
                            <p className="mt-2 text-sm text-gray-600">
                                Supported file types: {acceptedFileTypes.join(', ')}
                            </p>
                        </CardContent>
                    </Card>
    
                    <div className="space-y-4">
                        {filteredCards.map((card) => (
                            <Card 
                                key={card.id}
                                className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200"
                            >
                                <CardContent className="p-4 flex justify-between items-start">
                                    <div>
                                        <CardTitle className="text-lg font-semibold mb-2">{card.question}</CardTitle>
                                        <CardDescription>{card.answer}</CardDescription>
                                    </div>
                                    <div className="flex space-x-2">
                                        <Dialog>
                                            <DialogTrigger asChild>
                                                <Button variant="outline" size="icon" onClick={() => setEditingCard(card)}>
                                                    <Edit className="h-4 w-4" />
                                                </Button>
                                            </DialogTrigger>
                                            <DialogContent className="bg-white p-4 rounded-lg shadow-xl">
                                                <DialogHeader>
                                                    <DialogTitle className="text-xl font-bold mb-4">Edit Card</DialogTitle>
                                                </DialogHeader>
                                                {editingCard && (
                                                    <form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        handleEditCard(editingCard.id, {
                                                            question: e.target.question.value,
                                                            answer: e.target.answer.value
                                                        });
                                                    }}>
                                                        <Input
                                                            name="question"
                                                            defaultValue={editingCard.question}
                                                            placeholder="Question"
                                                            className="mb-2 w-full"
                                                        />
                                                        <Textarea
                                                            name="answer"
                                                            defaultValue={editingCard.answer}
                                                            placeholder="Answer"
                                                            className="mb-4 w-full"
                                                        />
                                                        <div className="flex justify-end space-x-2">
                                                            <Button type="button" variant="outline" onClick={() => setEditingCard(null)}>Cancel</Button>
                                                            <Button type="submit" className="bg-snapcards-blue text-white hover:bg-blue-700">Save Changes</Button>
                                                        </div>
                                                    </form>
                                                )}
                                            </DialogContent>
                                        </Dialog>
                                        <Button 
                                            variant="outline" 
                                            size="icon" 
                                            onClick={() => card.id ? handleDeleteCard(card.id) : console.error('Card has no ID')}
                                        >
                                            <Trash2 className="h-4 w-4 text-red-500 hover:text-red-700" />
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
    
                    <Button onClick={onBack} className="mt-8 bg-snapcards-blue text-white hover:bg-blue-700">
                        <ChevronLeft className="h-4 w-4 mr-2" /> Back to Main
                    </Button>
                </div>
            </main>
    
            <Footer />
    
            <dialog id="newCardDialog" className="p-6 rounded-lg shadow-xl bg-white w-full max-w-4xl">
                <div className="flex justify-between items-center mb-6">
                    <h3 className="text-2xl font-bold">Add New Card</h3>
                    <Button onClick={() => document.getElementById('newCardDialog').close()} variant="outline" size="icon">
                        <X className="h-4 w-4" />
                    </Button>
                </div>
                <div className="space-y-4">
                    <div>
                        <label htmlFor="question" className="block text-sm font-medium text-gray-700 mb-1">Question</label>
                        <Input 
                            id="question"
                            type="text" 
                            placeholder="Enter question" 
                            value={newCardQuestion}
                            onChange={(e) => setNewCardQuestion(e.target.value)}
                            className="w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="answer" className="block text-sm font-medium text-gray-700 mb-1">Answer</label>
                        <Textarea 
                            id="answer"
                            placeholder="Enter answer" 
                            value={newCardAnswer}
                            onChange={(e) => setNewCardAnswer(e.target.value)}
                            className="w-full h-32"
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-2 mt-6">
                    <Button onClick={() => document.getElementById('newCardDialog').close()} variant="outline">
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => {
                            handleAddCard();
                            document.getElementById('newCardDialog').close();
                        }} 
                        className="bg-snapcards-blue text-white hover:bg-blue-700"
                    >
                        Add Card
                    </Button>
                </div>
            </dialog>
        </div>
    );
};

export default DeckEditScreen;
